<template>
    <div class="invoice-container" id="printArea">
        <table class="w-full">
            <tr>
                <td class="w-half">
                    <h1 class="text-left heading">Tax Invoice</h1>
                </td>
                <td class="w-half text-right logo-container">
                    <img src="../../assets/digital-hub-logo.png" alt="digitalhub logo" class="logo" width="300" />
                    <p class="logo-content">
                        <span>Powered by</span> <br>
                        DRAKE BUSINESS LOGISTICS <br>
                        A.B.N.	27 160 148 658
                    </p>
                </td>

            </tr>
        </table>

        <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Invoice</b>#: {{ data.transaction_no ? data.transaction_no : '' }}
                        </div>
                        <div>
                            <b>Date</b>: {{ data.request_date }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Sales person</b>: {{ data.requester ? data.requester.name : '' }}
                        </div>
                        <div>
                            <b>Site</b>: {{ data.mailroom_address ? `${data.mailroom_address} - ` : '' }} Mailroom {{
                                data.mailroom_branch }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <!-- <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Booking Date</b>: {{ data.created_at | formatDate }}
                        </div>
                        <div>
                            <b>Booking Time</b>: {{ data.created_at | formatTime }}
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->

        <!-- <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Pickup Name</b>:  {{ data.pickup_name }}
                        </div>
                        <div>
                            <b>Pickup Address</b>: 
                                <span v-if="data.pickup_desk">Desk# {{ data.pickup_desk }}, </span>
                                <span v-if="data.pickup_floor">Floor# {{ data.pickup_floor }}, </span>
                                {{ data.pickup_address_1 ? data.pickup_address_1 + ', ' : '' }}
                                {{ data.pickup_address_2 ? data.pickup_address_2 + ', ' : '' }}
                                {{ data.pickup_city ? data.pickup_city + ', ':''}}
                                {{ data.pickup_state ? data.pickup_state + ', ' : '' }}
                                {{ data.pickup_postal_code ? data.pickup_postal_code + ', ' : '' }}
                                {{ data.pickup_country }}
                            
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->

        <!-- <div class="margin-top">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>Delivery Name</b>: {{ data.delivery_name }}
                        </div>
                        <div>
                            <b>Delivery Address</b>: 
                            <span v-if="data.delivery_desk">Desk# {{ data.delivery_desk }}, </span>
                            <span v-if="data.delivery_floor">Floor# {{ data.delivery_floor }}, </span>
                            {{ data.delivery_address_1 ? data.delivery_address_1 + ', ' : '' }}
                            {{ data.delivery_address_2 ? data.delivery_address_2 + ', ' : '' }}
                            {{ data.delivery_city ? data.delivery_city + ', ' : '' }}
                            {{ data.delivery_state ? data.delivery_state + ', ' : '' }}
                            {{ data.delivery_postal_code ? data.delivery_postal_code + ', ' : '' }}
                            {{ data.delivery_country }}
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->

        <!-- <div class="margin-top" v-if="data.custom_code && data.custom_code_value">
            <table class="w-full">
                <tr>
                    <td class="w-half text-left" colspan="2">
                        <div>
                            <b>{{ data.custom_code }}</b>: {{ data.custom_code_value }}
                        </div>
                    </td>
                </tr>
            </table>
        </div> -->

        <div class="margin-top">
            <table class="outbound">
                <thead>

                    <tr>
                        <th>QTY.</th>
                        <th>DESCRIPTION</th>
                        <th class="text-center">PRICE</th>
                        <th class="text-right">EXTENDED PRICE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="items">
                        <td>{{ data.item_qty }}</td>
                        <td>
                            {{ `${data.booking_prefix + data.booking_id}` }}
                        </td>
                        <td class="text-center">$ {{ (data.carrier_code == 'STARTRACK-TV' || data.carrier_code == 'STC') ? data.total_price.toFixed(2) : data.courier_rate.toFixed(2) }}</td>
                        <td class="text-right">$ {{ (data.carrier_code == 'STARTRACK-TV' || data.carrier_code == 'STC') ? data.total_price.toFixed(2) : data.courier_rate.toFixed(2) }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td rowspan="3">COMMENT</td>
                        <td colspan="2" class="text-right">
                            Amount AUD
                        </td>
                        <td class="text-right">
                            $ {{ (data.carrier_code == 'STARTRACK-TV' || data.carrier_code == 'STC') ? data.total_price.toFixed(2) : data.courier_rate.toFixed(2) }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-right">
                            GST
                        </td>
                        <td class="text-right">
                            $ {{  (data.carrier_code == 'STARTRACK-TV' || data.carrier_code == 'STC') ? data.gst_value.toFixed(2) : 0 }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-right">
                            Total Amount. AUD
                        </td>
                        <td class="text-right">
                            $ {{  (data.carrier_code == 'STARTRACK-TV' || data.carrier_code == 'STC') ? data.total_price_with_gst.toFixed(2) : data.courier_rate.toFixed(2) }}
                        </td>
                    </tr>

                </tfoot>
            </table>
        </div>

        <div class="footer margin-top text-right">
            <div class="content">THANK YOU FOR YOUR BUSINESS !</div>
            <div class="logo">
                <img src="../../assets/drake-logo.png" alt="digitalhub logo" width="150" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: 'OutboundInvoice',
    props: {
        data: Object,
    },
    filters: {
        formatTime(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('HH:mm');
        },
        formatDate(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('YYYY/MM/DD');
        },
    }
};
</script>

<style>
.invoice-container {
    /* padding-block: 4rem; */
    padding-inline: 2rem;

    background-color: white;
}

.heading {
    font-size: 35px;
    font-weight: 700;
}

h4 {
    margin: 0;
}

.w-full {
    width: 100%;
}

.w-half {
    width: 50%;
}

.margin-top {
    margin-top: 1.25rem;
}

.footer {
    font-size: 0.875rem;
    padding: 1rem;
    margin-top: 4rem;
}

.footer .logo {
    margin-top: 2rem;
}

.footer .content {
    font-weight: 600;
}

table {
    width: 100%;
    border-spacing: 0;
}

table tr.items td {
    padding: 0.5rem;
}

table.outbound {
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
}

table.outbound th,
table.outbound td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.total {
    text-align: right;
    margin-top: 1rem;
    font-size: 0.875rem;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}
.logo-content {
    font-size: 15px;
    text-align: right;
}
.logo-content span {
    font-weight: 700;
    font-size: 14 !important;
}
.logo-container .logo {
    position: relative;
    right: -9% !important;
}
</style>
